import React from "react"
import posed, { PoseGroup } from "react-pose";
import { FormattedMessage } from 'react-intl'
import { MdSend, MdCheckCircle } from 'react-icons/md';
import styled from "@emotion/styled"

const IconLoading = posed.div({
  before: { x: '-100%' },
  enter: { x: '0%' },
  exit: { x: '100%' },
});

const Icon = posed.div({
  before: { x: '-100%' },
  enter: { x: '0%' },
  exit: { x: '100%' },
});

const Button = styled.button`
  position: relative;
  overflow: hidden;
  width: 10rem;
  height: 2.5rem;
  transition: 0.3s;

  background-color: #3342AA;
  &.is-loading {
    background-color: #787DBB;
  }
`

const StatusIconLoading = styled(IconLoading)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StatusIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

class ComponentButton extends React.Component {


  render() {

    const props = this.props
    const loading = (props.loading === true) ? true : false
    const btnStatus = (loading) ? 'is-loading' : ''

    return (

      <Button type="submit" disabled={loading} className={`text-white font-semibold rounded-full focus:outline-none ${btnStatus}`}>
        <PoseGroup animateOnMount preEnterPose="before">
          {loading && (
            <StatusIconLoading className="status__icon" key={1}>
              <FormattedMessage id="Sending" />
              <MdCheckCircle className="ml-2"/>
            </StatusIconLoading>
          )}
          {!loading && (
            <StatusIcon className="status__icon" key={2}>
              <FormattedMessage id="Send" />
              <MdSend className="ml-2"/> 
            </StatusIcon>
          )}
        </PoseGroup>
      </Button>
    )
  }
}

export default ComponentButton